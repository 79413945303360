<template>
    <HXContentCard title="Listado Vehículos">
        <!-- Pantalla de carga -->
        <transition name="fade">
            <div v-if="loading" class="text-center">
                <div class="">
                    <div class="emoji-container">
                        <span class="emoji" role="img" aria-label="Car">🚗</span>
                        <span class="emoji" role="img" aria-label="Hourglass">⏳</span>
                        <span class="emoji" role="img" aria-label="Thinking">🤔</span>
                    </div>
                    <span class="h2 font-weight-bold">Cargando datos...</span>
                    <p class="loading-message">Espere un momento mientras preparamos la información.</p>
                </div>
            </div>
        </transition>

        <!-- Contenido principal -->
        <div v-if="!loading">
            <!-- Tarjetas de Resumen -->
            <div class="px-4 mt-3">

                <CardTable :data="dataCards" />
            </div>


            <!-- Tabla de Vehículos -->
            <div class="px-4 mt-3">
                <table id="datatable_registroServicios"
                    class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                        <tr>
                            <th>Placa Vehículo</th>
                            <th>Sucursal</th>
                            <th>Estado del Vehículo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item) in listaItems" :key="item.id" class="animated-row">
                            <td>
                                {{ item.placa }}
                            </td>
                            <td>{{ item.descripcion }}</td>
                            <td>
                                <span class="badge" :class="{
                                    'badge-success': item.estadoVehiculo === 'Disponible',
                                    'badge-danger': item.estadoVehiculo === 'No Disponible'
                                }" :title="'Estado: ' + item.estadoVehiculo">
                                    {{ item.estadoVehiculo }}
                                </span>
                            </td>
                            <td>
                                <vs-button info v-b-tooltip="{
                                    title: 'Ver Conductor asignado',
                                    placement: 'top',
                                    customClass: 'font_tooltip',
                                }" @click.prevent="getInfoVehiculoPorPlaca(item.placa)" icon>
                                    <i class="fa-solid fa-truck-ramp-box"></i>
                                </vs-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal con la información del Vehículo -->
        <vs-dialog v-model="showModal" width="550px" not-center>
            <div class="modal-header">
                <i class="fas fa-car-side fa-3x text-primary"></i>
                <span class="modal-title text-center">¡Información del vehículo!</span>
            </div>

            <div v-if="vehiculo" class="modal-content animated fadeIn">
                <div class="vehicle-details">
                    <div class="detail-item">
                        <i class="fas fa-id-card fa-2x text-info"></i>
                        <div>
                            <h5>Placa</h5>
                            <p>{{ vehiculo.placa }}</p>
                        </div>
                    </div>
                    <div class="detail-item">
                        <i class="fas fa-cogs fa-2x text-warning"></i>
                        <div>
                            <h5>Último modelo asignado</h5>
                            <p>{{ vehiculo.marca || 'No disponible' }}</p>
                        </div>
                    </div>
                    <div class="detail-item">
                        <i class="fas fa-user-tie fa-2x text-success"></i>
                        <div>
                            <h5>Último conductor asignado</h5>
                            <p>{{ vehiculo.conductor || 'No disponible' }}</p>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <p class="ready-message"
                        :class="{ 'available': vehiculo.estadoVehiculo === 'Disponible', 'unavailable': vehiculo.estadoVehiculo === 'No Disponible' }">
                        <i
                            :class="vehiculo.estadoVehiculo === 'Disponible' ? 'fas fa-check-circle' : 'fas fa-times-circle'"></i>
                        {{ vehiculo.estadoVehiculo ===
                            'Disponible' ? '¡Listo para la acción!' : 'No disponible en este momento' }}
                    </p>
                </div>
            </div>
            <div v-else class="modal-content animated fadeIn">
                <div class="text-center">
                    <i class="fas fa-exclamation-triangle fa-3x text-warning mb-3"></i>
                    <h4>No se encontró información para este vehículo</h4>
                    <p>Por favor, intente nuevamente más tarde.</p>
                </div>
            </div>
        </vs-dialog>
    </HXContentCard>
</template>

<script>
import CardTable from "../../../components/cardTable/CardTable.vue";

export default {
    name: "DashboardFlota",
    components: {
        CardTable,
    },
    data() {
        return {
            persona: {},
            listaItems: [],
            loading: true,
            vehiculo: null,
            listaInfoVehiculos: {},
            showModal: false,
            dataCards: [
                {
                    title: "Total de Vehículos",
                    value: () => this.listaItems.length,
                    iconName: "fas fa-car",
                    color: "rgba(254, 114, 28, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                    onDeactivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                },
                {
                    title: "Vehículos Disponibles",
                    value: () => this.listaItems.filter(item => item.estadoVehiculo === "Disponible").length,
                    iconName: "fas fa-check-circle",
                    color: "rgba(22, 162, 117, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                    onDeactivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                },
                {
                    title: "Vehículos No Disponibles",
                    value: () => this.listaItems.filter(item => item.estadoVehiculo === "No Disponible").length,
                    iconName: "fas fa-times-circle",
                    color: "rgba(71, 136, 255, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                    onDeactivate: () => {
                        this.dataTableInstance.search("").draw();
                    },
                },
            ],
        };
    },
    computed: {
        availableVehiclesCount() {
            return this.listaItems.filter(item => item.estadoVehiculo === "Disponible").length;
        },
        unavailableVehiclesCount() {
            return this.listaItems.filter(item => item.estadoVehiculo === "No Disponible").length;
        },
    },
    watch: {
        listaItems: {
            handler(newValue) {
                this.summaryCards[0].value = newValue.length;
                this.summaryCards[1].value = this.availableVehiclesCount;
                this.summaryCards[2].value = this.unavailableVehiclesCount;
            },
            immediate: true,
        },
    },
    async mounted() {
        this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        await this.getData();

        this.$nextTick(() => {
            if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
                window.$("#datatable_registroServicios").DataTable().destroy();
            }
            this.dataTableInstance = window
                .$("#datatable_registroServicios")
                .DataTable({
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
                    },
                });
        });
    },
    methods: {
        async getData() {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "VehiculosDisponibles/GetVehiculosDisponibles/" + this.persona.empresa.id,
                });
                if (res.success) {
                    this.listaItems = res.data;
                } else {
                    this.listaItems = [];
                    console.log("No se encontraron vehículos para esta empresa");
                }
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            } finally {
                this.loading = false;
            }
        },
        async getInfoVehiculoPorPlaca(placa) {
            try {
                // Realizar la petición al endpoint
                let res = await this.$store.dispatch("hl_get", {
                    path: `Vehiculo/GetConductorModeloVehiculo/${placa}`,
                });

                const data = await res;

                if (
                    (!data.informacionGeneral || data.informacionGeneral.length === 0) &&
                    (!data.conductoresAsignados || data.conductoresAsignados.length === 0)
                ) {
                    this.vehiculo = null; // No hay datos
                    this.showModal = true;
                    return;
                }

                // Llenar los datos del vehículo
                this.vehiculo = {
                    placa: data.vehiculo?.placa || 'No disponible',
                    marca: data.informacionGeneral?.at(-1)?.marca || 'No disponible',
                    conductor: data.conductoresAsignados?.at(-1)?.conductorAsignado?.nombresApellidosConductor?.nombres + ' ' +
                        data.conductoresAsignados?.at(-1)?.conductorAsignado?.nombresApellidosConductor?.apellidos || 'No disponible',
                    estadoVehiculo: data.vehiculo?.estadoVehiculo || 'No Disponible'
                };

                // Mostrar el modal con los datos
                this.showModal = true;

            } catch (error) {
                console.error('Error al obtener información del vehículo:', error);

                this.vehiculo = null; // No hay datos
                this.showModal = true; // Mostrar modal de error
            }
        }
    },
};
</script>

<style scoped>
.modal-content {
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-title {
    font-size: 1.8rem;
    margin-left: 10px;
}

.vehicle-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.detail-item {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.detail-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.detail-item i {
    margin-right: 15px;
}

.detail-item h5 {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 5px;
}

.detail-item p {
    font-size: 1.1rem;
    color: #7f8c8d;
    margin: 0;
}

.ready-message {
    font-size: 1.3rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 50px;
    transition: all 0.3s ease;
}

.ready-message.available {
    background-color: #27ae60;
    color: white;
}

.ready-message.unavailable {
    background-color: #e74c3c;
    color: white;
}

.ready-message i {
    margin-right: 10px;
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

/* Estilos adicionales para mejorar la apariencia general */
.emoji-container {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.animated-card {
    transition: all 0.3s ease;
}

.animated-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.animated-row {
    transition: background-color 0.3s ease;
}

.animated-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>